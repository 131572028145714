import React from "react";

import DashBoardImage from "../../assets/image/home-3/png/ReflexxSC.png";
import YellowShape from "../../assets/image/home-3/png/yellow-shape.png";
import GreenShape from "../../assets/image/home-3/png/output-onlinepngtools.png";
import { Link } from "gatsby";

const Hero = ({className,...rest}) => {
    return (
      <div className={className} {...rest}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-7 col-md-9">
              <div
                className="text-center mb-11 mb-lg-24"
                data-aos="zoom-in"
                data-aos-delay={500}
              >
                <h1 className="font-size-11 mb-7">
                  Unite with  Local Businesses
                </h1>
                <p className="font-size-7 px-lg-5 mb-0">
                  Join the ring of local businesses in your area looking to grow together.
                </p>
                <div className="btn-group d-inline-block mt-10">
                <a href="https://calendly.com/akshayakula/coffee-chat">
                <button class="btn btn btn-black header-btn-10 rounded-10">Book a Demo</button>
              </a>
                  <p className="d-block mt-6 font-size-3 mb-0 text-bali-gray letter-spacing-reset">
                    No credit card required
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* Hero Image Group */}
          <div className="row justify-content-center">
            <div className="col-md-10 col-xs-11">
              <div className="l3-hero-image-group w-100">
                {/* Image One */}
                <div
                  className="img-1 shadow-13"
                  data-aos="fade-up"
                  data-aos-delay={500}
                  data-aos-once="true"
                >
                  <img max-width="20vw" src="https://images.unsplash.com/photo-1556741533-6e6a62bd8b49?q=80&w=3540&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="" />
                  {/* Image Two */}
                  <div className="img-2">
                    <img
                      src={GreenShape}
                      alt=""
                      data-aos="fade-right"
                      data-aos-delay={700}
                      data-aos-once="true"
                    />
                  </div>
                  {/* Image Three */}
                  <div className="img-3">
                    <img
                      src={GreenShape}
                      alt=""
                      data-aos="fade-left"
                      data-aos-delay={900}
                      data-aos-once="true"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End Hero Image Group */}
        </div>
      </div>
    );
}


export default Hero;